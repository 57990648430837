@font-face {
    font-family: 'Lato Regular';
    src: url('../fonts/Lato-Regular.ttf'); 
}

@font-face {
    font-family: 'Lato Bold';
    src: url('../fonts/Lato-Bold.ttf'); 
}

@font-face {
    font-family: 'Lato Black';
    src: url('../fonts/Lato-Black.ttf'); 
}

@font-face {
    font-family: 'Lato Light';
    src: url('../fonts/Lato-Light.ttf'); 
}

@font-face {
    font-family: 'Lato Medium';
    src: url('../fonts/Lato-Medium.ttf'); 
}

@font-face {
    font-family: 'Rouble';
    src: url('../fonts/rouble.otf'); 
}